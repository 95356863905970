import _stringify from "./stringify";
import _traversal from "./traversal";
import _manipulation from "./manipulation";
import _querying from "./querying";
import _legacy from "./legacy";
import _helpers from "./helpers";
import _feeds from "./feeds";
import * as _domhandler2 from "domhandler";

var _domhandler = "default" in _domhandler2 ? _domhandler2.default : _domhandler2;

var exports = {};

var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __exportStar = exports && exports.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasChildren = exports.isDocument = exports.isComment = exports.isText = exports.isCDATA = exports.isTag = void 0;

__exportStar(_stringify, exports);

__exportStar(_traversal, exports);

__exportStar(_manipulation, exports);

__exportStar(_querying, exports);

__exportStar(_legacy, exports);

__exportStar(_helpers, exports);

__exportStar(_feeds, exports);
/** @deprecated Use these methods from `domhandler` directly. */


var domhandler_1 = _domhandler;
Object.defineProperty(exports, "isTag", {
  enumerable: true,
  get: function () {
    return domhandler_1.isTag;
  }
});
Object.defineProperty(exports, "isCDATA", {
  enumerable: true,
  get: function () {
    return domhandler_1.isCDATA;
  }
});
Object.defineProperty(exports, "isText", {
  enumerable: true,
  get: function () {
    return domhandler_1.isText;
  }
});
Object.defineProperty(exports, "isComment", {
  enumerable: true,
  get: function () {
    return domhandler_1.isComment;
  }
});
Object.defineProperty(exports, "isDocument", {
  enumerable: true,
  get: function () {
    return domhandler_1.isDocument;
  }
});
Object.defineProperty(exports, "hasChildren", {
  enumerable: true,
  get: function () {
    return domhandler_1.hasChildren;
  }
});
export default exports;
export const __esModule = exports.__esModule,
      hasChildren = exports.hasChildren,
      isDocument = exports.isDocument,
      isComment = exports.isComment,
      isText = exports.isText,
      isCDATA = exports.isCDATA,
      isTag = exports.isTag,
      innerText = exports.innerText,
      textContent = exports.textContent,
      getText = exports.getText,
      getInnerHTML = exports.getInnerHTML,
      getOuterHTML = exports.getOuterHTML,
      prevElementSibling = exports.prevElementSibling,
      nextElementSibling = exports.nextElementSibling,
      getName = exports.getName,
      hasAttrib = exports.hasAttrib,
      getAttributeValue = exports.getAttributeValue,
      getSiblings = exports.getSiblings,
      getParent = exports.getParent,
      getChildren = exports.getChildren,
      prepend = exports.prepend,
      prependChild = exports.prependChild,
      append = exports.append,
      appendChild = exports.appendChild,
      replaceElement = exports.replaceElement,
      removeElement = exports.removeElement,
      findAll = exports.findAll,
      existsOne = exports.existsOne,
      findOne = exports.findOne,
      findOneChild = exports.findOneChild,
      find = exports.find,
      filter = exports.filter,
      getElementsByTagType = exports.getElementsByTagType,
      getElementsByTagName = exports.getElementsByTagName,
      getElementById = exports.getElementById,
      getElements = exports.getElements,
      testElement = exports.testElement,
      uniqueSort = exports.uniqueSort,
      compareDocumentPosition = exports.compareDocumentPosition,
      removeSubsets = exports.removeSubsets,
      getFeed = exports.getFeed;