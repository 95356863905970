import * as _domhandler2 from "domhandler";

var _domhandler = "default" in _domhandler2 ? _domhandler2.default : _domhandler2;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.prevElementSibling = exports.nextElementSibling = exports.getName = exports.hasAttrib = exports.getAttributeValue = exports.getSiblings = exports.getParent = exports.getChildren = void 0;
var domhandler_1 = _domhandler;
var emptyArray = [];
/**
 * Get a node's children.
 *
 * @param elem Node to get the children of.
 * @returns `elem`'s children, or an empty array.
 */

function getChildren(elem) {
  var _a;

  return (_a = elem.children) !== null && _a !== void 0 ? _a : emptyArray;
}

exports.getChildren = getChildren;
/**
 * Get a node's parent.
 *
 * @param elem Node to get the parent of.
 * @returns `elem`'s parent node.
 */

function getParent(elem) {
  return elem.parent || null;
}

exports.getParent = getParent;
/**
 * Gets an elements siblings, including the element itself.
 *
 * Attempts to get the children through the element's parent first.
 * If we don't have a parent (the element is a root node),
 * we walk the element's `prev` & `next` to get all remaining nodes.
 *
 * @param elem Element to get the siblings of.
 * @returns `elem`'s siblings.
 */

function getSiblings(elem) {
  var _a, _b;

  var parent = getParent(elem);
  if (parent != null) return getChildren(parent);
  var siblings = [elem];
  var prev = elem.prev,
      next = elem.next;

  while (prev != null) {
    siblings.unshift(prev);
    _a = prev, prev = _a.prev;
  }

  while (next != null) {
    siblings.push(next);
    _b = next, next = _b.next;
  }

  return siblings;
}

exports.getSiblings = getSiblings;
/**
 * Gets an attribute from an element.
 *
 * @param elem Element to check.
 * @param name Attribute name to retrieve.
 * @returns The element's attribute value, or `undefined`.
 */

function getAttributeValue(elem, name) {
  var _a;

  return (_a = elem.attribs) === null || _a === void 0 ? void 0 : _a[name];
}

exports.getAttributeValue = getAttributeValue;
/**
 * Checks whether an element has an attribute.
 *
 * @param elem Element to check.
 * @param name Attribute name to look for.
 * @returns Returns whether `elem` has the attribute `name`.
 */

function hasAttrib(elem, name) {
  return elem.attribs != null && Object.prototype.hasOwnProperty.call(elem.attribs, name) && elem.attribs[name] != null;
}

exports.hasAttrib = hasAttrib;
/**
 * Get the tag name of an element.
 *
 * @param elem The element to get the name for.
 * @returns The tag name of `elem`.
 */

function getName(elem) {
  return elem.name;
}

exports.getName = getName;
/**
 * Returns the next element sibling of a node.
 *
 * @param elem The element to get the next sibling of.
 * @returns `elem`'s next sibling that is a tag.
 */

function nextElementSibling(elem) {
  var _a;

  var next = elem.next;

  while (next !== null && !(0, domhandler_1.isTag)(next)) _a = next, next = _a.next;

  return next;
}

exports.nextElementSibling = nextElementSibling;
/**
 * Returns the previous element sibling of a node.
 *
 * @param elem The element to get the previous sibling of.
 * @returns `elem`'s previous sibling that is a tag.
 */

function prevElementSibling(elem) {
  var _a;

  var prev = elem.prev;

  while (prev !== null && !(0, domhandler_1.isTag)(prev)) _a = prev, prev = _a.prev;

  return prev;
}

exports.prevElementSibling = prevElementSibling;
export default exports;