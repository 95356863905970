import * as _domhandler2 from "domhandler";

var _domhandler = "default" in _domhandler2 ? _domhandler2.default : _domhandler2;

import _querying from "./querying";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getElementsByTagType = exports.getElementsByTagName = exports.getElementById = exports.getElements = exports.testElement = void 0;
var domhandler_1 = _domhandler;
var querying_1 = _querying;
var Checks = {
  tag_name: function (name) {
    if (typeof name === "function") {
      return function (elem) {
        return (0, domhandler_1.isTag)(elem) && name(elem.name);
      };
    } else if (name === "*") {
      return domhandler_1.isTag;
    }

    return function (elem) {
      return (0, domhandler_1.isTag)(elem) && elem.name === name;
    };
  },
  tag_type: function (type) {
    if (typeof type === "function") {
      return function (elem) {
        return type(elem.type);
      };
    }

    return function (elem) {
      return elem.type === type;
    };
  },
  tag_contains: function (data) {
    if (typeof data === "function") {
      return function (elem) {
        return (0, domhandler_1.isText)(elem) && data(elem.data);
      };
    }

    return function (elem) {
      return (0, domhandler_1.isText)(elem) && elem.data === data;
    };
  }
};
/**
 * @param attrib Attribute to check.
 * @param value Attribute value to look for.
 * @returns A function to check whether the a node has an attribute with a particular value.
 */

function getAttribCheck(attrib, value) {
  if (typeof value === "function") {
    return function (elem) {
      return (0, domhandler_1.isTag)(elem) && value(elem.attribs[attrib]);
    };
  }

  return function (elem) {
    return (0, domhandler_1.isTag)(elem) && elem.attribs[attrib] === value;
  };
}
/**
 * @param a First function to combine.
 * @param b Second function to combine.
 * @returns A function taking a node and returning `true` if either
 * of the input functions returns `true` for the node.
 */


function combineFuncs(a, b) {
  return function (elem) {
    return a(elem) || b(elem);
  };
}
/**
 * @param options An object describing nodes to look for.
 * @returns A function executing all checks in `options` and returning `true`
 * if any of them match a node.
 */


function compileTest(options) {
  var funcs = Object.keys(options).map(function (key) {
    var value = options[key];
    return Object.prototype.hasOwnProperty.call(Checks, key) ? Checks[key](value) : getAttribCheck(key, value);
  });
  return funcs.length === 0 ? null : funcs.reduce(combineFuncs);
}
/**
 * @param options An object describing nodes to look for.
 * @param node The element to test.
 * @returns Whether the element matches the description in `options`.
 */


function testElement(options, node) {
  var test = compileTest(options);
  return test ? test(node) : true;
}

exports.testElement = testElement;
/**
 * @param options An object describing nodes to look for.
 * @param nodes Nodes to search through.
 * @param recurse Also consider child nodes.
 * @param limit Maximum number of nodes to return.
 * @returns All nodes that match `options`.
 */

function getElements(options, nodes, recurse, limit) {
  if (limit === void 0) {
    limit = Infinity;
  }

  var test = compileTest(options);
  return test ? (0, querying_1.filter)(test, nodes, recurse, limit) : [];
}

exports.getElements = getElements;
/**
 * @param id The unique ID attribute value to look for.
 * @param nodes Nodes to search through.
 * @param recurse Also consider child nodes.
 * @returns The node with the supplied ID.
 */

function getElementById(id, nodes, recurse) {
  if (recurse === void 0) {
    recurse = true;
  }

  if (!Array.isArray(nodes)) nodes = [nodes];
  return (0, querying_1.findOne)(getAttribCheck("id", id), nodes, recurse);
}

exports.getElementById = getElementById;
/**
 * @param tagName Tag name to search for.
 * @param nodes Nodes to search through.
 * @param recurse Also consider child nodes.
 * @param limit Maximum number of nodes to return.
 * @returns All nodes with the supplied `tagName`.
 */

function getElementsByTagName(tagName, nodes, recurse, limit) {
  if (recurse === void 0) {
    recurse = true;
  }

  if (limit === void 0) {
    limit = Infinity;
  }

  return (0, querying_1.filter)(Checks.tag_name(tagName), nodes, recurse, limit);
}

exports.getElementsByTagName = getElementsByTagName;
/**
 * @param type Element type to look for.
 * @param nodes Nodes to search through.
 * @param recurse Also consider child nodes.
 * @param limit Maximum number of nodes to return.
 * @returns All nodes with the supplied `type`.
 */

function getElementsByTagType(type, nodes, recurse, limit) {
  if (recurse === void 0) {
    recurse = true;
  }

  if (limit === void 0) {
    limit = Infinity;
  }

  return (0, querying_1.filter)(Checks.tag_type(type), nodes, recurse, limit);
}

exports.getElementsByTagType = getElementsByTagType;
export default exports;